<template>
	<main>
		<HeaderTab :title="$t('global.titre_tiers_liste')" :return_action="'navigationBack'"/>
		<!-- integrer le header et le swiper -->
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<CustomTable
								ref="table"
								id_table="tiers"
								:busy.sync="table_busy"
								primaryKey="tiers_id"
								:hrefsRoutes="config_table_hrefs"
								:hide_if_empty="true"
								:items="tiers"
								:base-filters="filters"
				                :externSlotColumns="['tiers_tags', 'all_client_account', 'tiers_mail', 'phones_number', 'tiers_statut_rcs', 'tiers_statut_rne', 'tiers_vatcheck']"
								:groupByCustom="customGroupBy"
							>
								<template v-slot:[`custom-slot-cell(tiers_mail)`]="{ data }">
									<e-copybox classes="row" mail customtable :value="data.tiers_mail"></e-copybox>                                             
								</template>
								<template v-slot:[`custom-slot-cell(phones_number)`]="{ data }">
									<span v-for="(phone_number, index) in data.phones_number.split('<br>')" :key="index">
										<e-copybox classes="row" phone customtable :value="phone_number"></e-copybox>                                             
									</span>
								</template>
								<template v-slot:[`custom-slot-cell(tiers_tags)`]="{ data }">
							        <div class="row">
						    	        <span v-for="color in data.tiers_tags" class="tags" :style="'background-color:'+color" :key="color"></span>
						        	</div>
								</template>
								<template v-slot:[`custom-slot-cell(all_client_account)`]="{ data }">
					    	        <span v-for="client_account in data.all_client_account" :key="client_account.tiersclientaccount_id">
					    	        	{{ client_account.accountingplan.tiers.tiers_rs }} : {{ client_account.tiersclientaccount_number }}<br>
					    	        </span>
								</template>
								<template v-slot:[`custom-slot-cell(tiers_statut_rcs)`]="{ data }">
									<span v-if="data.tiers_statut_rcs && data.tiers_statut_rcs !== ''">{{ data.tiers_statut_rcs }}</span>
									<a v-else-if="data.tiers_country == 75" href="" @click.prevent="openTiersPappersModal(data)">
										{{ $t('tiers.pappers_update') }}
									</a>
								</template>
								<template v-slot:[`custom-slot-cell(tiers_statut_rne)`]="{ data }">
									<span v-if="data.tiers_statut_rne && data.tiers_statut_rne !== ''">{{ data.tiers_statut_rne }}</span>
									<a v-else-if="data.tiers_country == 75" href="" @click.prevent="openTiersPappersModal(data)">
										{{ $t('tiers.pappers_update') }}
									</a>
								</template>
								<template v-slot:[`custom-slot-cell(tiers_vatcheck)`]="{ data }">
									<font-awesome-icon class="text-success" v-if="data.tiers_vatcheck == 1" :icon="['far', 'check-circle']" />
									<font-awesome-icon class="text-danger" v-else-if="data.tiers_vatcheck == 2" :icon="['far', 'times-circle']" />
								</template>
							</CustomTable>
						</div>
					</div>
				</div>
			</div>
		</div>
		<TiersModal 
            from="liste"
        ></TiersModal>
		<ModalEditQualification ref="modal_edit_qualification"/>
		<ModalPrintInfosTiers ref="modal_print_infos_tiers"/>
		<ModalSendMail ref="modal_send_mail"/>
		<ModalTiersPappers ref="modal_tiers_pappers" from="tiersListe" @tiers="refreshTable"/>
		<TiersImport />
	</main>
</template>

<script type="text/javascript">
import Vue from 'vue'
import { EventBus } from 'EventBus'
import Navigation from "@/mixins/Navigation.js"
import Tiers from "@/mixins/Tiers.js"

export default {
	name: "TiersListe",
	mixins: [Navigation, Tiers],
	data () {
		return {
			/* Configuration du tableau : */
			table_busy: false,
			tiers: [],
			config_table_hrefs: {
				'tiers_rs': {
					routeUniqueName: 'tiersFiche',
					routeName: 'tiersFiche',
					params: {
						tiers_id: 'tiers_id'
					}
				}
			},
			rawColumnNames: ['phones_number'],
			events_tab: {
				'TableAction::goToPrintInfosTiers': (tiers_ids) => {
					this.openModalPrintInfosTiers(tiers_ids)
				},
				'TableAction::goToArchiveTiers': (tiers_ids) => {
					this.archive(tiers_ids)
				},
				'TableAction::goToExportTiers': () => {
					this.export_tiers()
					.then(() => {
						EventBus.$emit("TableAction::stopSpin")
					})
				},
				'TableAction::goToManageQualifsTiers': (tiers_ids) => {
					this.openModalQualifs(tiers_ids)
				},
				'TableAction::goToSendMailTiers': (tiers_ids) => {
					this.openModalSendMail(tiers_ids)
				},
			},
			customGroupBy: {'tiers_vatcheck': this.vatcheckGroupBy},
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.table_busy = true
			this.tiers = await this.loadTiersOnline()
			this.table_busy = false
		},
		openModalPrintInfosTiers(tiers_ids) {
			this.$refs.modal_print_infos_tiers.openModal(tiers_ids)
		},
		openTiersPappersModal(tiers){
			this.$refs.modal_tiers_pappers.openModal(tiers)
		},
		async archive(tiers_ids) {
			this.archiveTiers(tiers_ids)
			.then(() => {
				this.refreshTable()
			})
		},
		async refreshTable(){
			await this.init_component()
			this.$refs.table.refreshTable()
		},
		openModalQualifs(tiers_ids) {
			this.$refs.modal_edit_qualification.openModalFromTiers(tiers_ids)
		},
		openModalSendMail(tiers_ids) {
			if(this.hasMailTiersAccess) {
				this.$refs.modal_send_mail.openModal(tiers_ids, 'tiers_notification', 'tiers_notification')
			}
			else {
				this.infoToast('toast.contact_equideclic_evolution')
			}
		},
		vatcheckGroupBy(vatcheck){
			switch (vatcheck) {
				case 1:
					return this.getTrad('tiers.has_valid_vat')
				case 2:
					return this.getTrad('tiers.has_invalid_vat')
				case this.getTrad('tiers.has_valid_vat'):
					return this.getTrad('tiers.has_valid_vat')
				case this.getTrad('tiers.has_invalid_vat'):
					return this.getTrad('tiers.has_invalid_vat')
				default:
					return this.getTrad('tiers.has_unverified_vat')
			}
		}
	},
	computed: {
		filters() {
			let filters = {
				tiers: {
					column: 'tiers_archive',
					operator: 'isEqualTo',
					value: 0
				}
			}

			return filters
		},
		hasMailTiersAccess() {
			return this.$store.state.userAccess.hasMailTiersAccess
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		TiersModal: () => import('@/components/Tiers/Form'),
		ModalEditQualification: () => import('@/components/Contract/ModalEditQualification'),
		ModalPrintInfosTiers : () => import('@/components/Tiers/ModalPrintInfosTiers'),
		ModalTiersPappers : () => import('@/components/Modals/ModalTiersPappers'),
		ModalSendMail: () => import('@/components/Modals/ModalSendMail'),
		TiersImport: () => import('@/components/Tiers/TiersImport'),

	}
}
</script>
